import React from 'react';
import PropTypes from 'prop-types';
// TODO: remove Flickity
// import dynamic from 'next/dynamic';
import Flickity from 'react-flickity-component';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useDomLoaded } from '../../utils/hooks';

// TODO: Re-enable this dynamic import, removed for causing a
// build error. You can't feed a loading dynamic import to styled()
// const Flickity = process.env.NODE_ENV === 'test'
//   ? require('react-flickity-component')
//   : dynamic(
//     () => import('react-flickity-component'),
//     { ssr: false }
//   );

const CHILD_CLASS = 'carousel-cell';

const carouselBaseStyles = css`
  position: relative;

  .${CHILD_CLASS} {
    height: auto;
    position: relative;
    width: 100%;
  }
`;

const carouselArrowStyles = (theme, hasUpToFiveProducts, type, adjustArrows) => css`
  .flickity-prev-next-button {
    background: transparent;
    border-radius: 100%;
    border: 1px solid ${theme.color.brand};
    color: transparent;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 3rem;
    line-height: 0;
    opacity: .75;
    outline: none;
    padding: 0;
    position: absolute;
    stroke: ${theme.color.brand};
    top: 50%;
    transform: translate(0, -50%);
    transition: opacity ${theme.animation.default} ${theme.animation.easeOutQuad};
    width: 3rem;
    :hover {
      background: transparent;
      color: transparent;
      opacity: 1;
      outline: none;
    }
    svg {
      fill: ${theme.color.brand};
      height: 1rem;
      stroke-width: 2;
      width: 1rem;
    }
    &.previous {
      left: -3rem;
    }
    &.next {
      right: -3rem;
      left: auto;
    }
    :disabled {
      cursor: not-allowed;
      opacity: .25;
    }

    ${hasUpToFiveProducts && css`
      @media (min-width: ${theme.breakpoint.medium}) {
        display: none;
      }
    `}

    ${type === 'hero' && css`
      display: none;

      @media screen and (min-width: ${theme.breakpoint.large}) {
        border: none;
        background: ${theme.color.white};
        color: ${theme.color.bluePrimary};
        opacity: 1;
        &.previous {
          left: ${theme.modularScale.sixteen};
        }

        &.next {
          right: ${theme.modularScale.sixteen};
        }

        &.next, &.previous {
          // adjust arrow placement relative to image (variable height) vs entire carousel,
          // if heading (static height) is present, adjustArrows will be true
          // 3.5rem (heading) + 3.2rem (heading padding) - 1.5rem (half arrow) = 5.2rem
          top: ${adjustArrows ? 'calc(50% - 5.2rem)' : '50%'};
        }

        // hero carousel only displays arrows on hover
        // override above hover behavior
        :hover {
          background: ${theme.color.white};
          color: ${theme.color.bluePrimary};
        }
      }
    `}
  }
`;

const carouselDotsStyles = (theme, type) => css`
  .flickity-page-dots {
    display: flex;
    height: 3rem;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    width: 100%;

    .dot {
      align-items: center;
      cursor: pointer;
      display: inline-flex;
      height: 3rem;
      justify-content: center;
      padding: 0;
      position: relative;
      width: 2rem;

      ::before {
        background: ${theme.color.brandLight};
        content: '';
        border-radius: 99999px;
        transition: background ${theme.animation.default} ${theme.animation.easeOutQuad};
        height: 1.0rem;
        width: 1.0rem;
      }

      :hover {
        ::before {
          opacity: 1;
          background: ${theme.color.brand};
        }
      }

      &.is-selected {
        ::before {
          opacity: .75;
          background: ${theme.color.brand};
        }
      }
    }

    ${type === 'hero' && css`
      margin-top: ${theme.modularScale.sixteen};

      &, .dot {
        height: 0.8rem;
      }

      .dot {
        ::before {
          height: 0.8rem;
          width: 0.8rem;
        }

        &.is-selected {
          ::before {
            background: ${theme.color.bluePrimary};
          }
        }
      }

      @media screen and (min-width: ${theme.breakpoint.large}) {
        margin-top: ${theme.modularScale.thirtyTwo};
      }
    `}

    ${type === 'pdp' && css`
      margin-top: 20px;
      @media screen and (max-width: ${theme.breakpoint['x-small']}) {
        display: flex;
      }
      .dot {
        ::before {
          height: 1.0rem;
          width: 1.0rem;
        }
        &.is-selected {
          ::before {
            background: ${theme.color.bluePrimary};
          }
        }
      }
      @media screen and (min-width: 769px) {
        display: none;
      }

      @media screen and (max-width: ${theme.breakpoint.large}) {
        margin-top: 25px;
      }

    `}
  }
`;

const carouselCellStyles = ({
  type,
  theme,
  hasUpToFiveProducts,
  adjustArrows
}) => {
  const styles = css`
    ${carouselBaseStyles}
    ${carouselArrowStyles(theme, hasUpToFiveProducts, type, adjustArrows)}
    :not(.no-dots) {
      ${carouselDotsStyles(theme, type)}
    }
  `;

  if (type === 'product-card') {
    return css`
      ${styles}
      margin: 3rem;
      position: relative;

      .flickity-viewport {
        overflow: hidden;
      }

      .${CHILD_CLASS} {
        @media screen and (min-width: ${theme.breakpoint.small}) {
          width: 50%;
        }

        @media screen and (min-width: ${theme.breakpoint.medium}) {
          width: 20%;
        }
      }
    `;
  }

  if (type === 'three-ways') {
    return css`
      ${styles}

      .flickity-viewport {
        overflow: hidden;
      }

      .${CHILD_CLASS} {
        @media screen and (min-width: ${theme.breakpoint.medium}) {
          width: 33.33333%;
        }
      }
    `;
  }

  if (type === 'hero') {
    return css`
      ${styles}
      overflow: hidden;

      .${CHILD_CLASS} {
        margin-right: ${theme.modularScale.sixteen};

        @media screen and (min-width: ${theme.breakpoint.large}) {
          margin-right: 9.5vw;
        }
      }
    `;
  }

  if (type === 'quickshop') {
    return css`
      ${styles}
      overflow: hidden;
      margin: 0 3rem;
    `;
  }

  if (type === 'pdp') {
    return css`
      ${styles}
      margin: 0 3rem;

      .flickity-viewport {
        overflow: hidden;
      }

      .${CHILD_CLASS} {
        padding: 2rem;
      }

      @media screen and (max-width: ${theme.breakpoint['x-small']}) {
        margin: 0rem;

        .${CHILD_CLASS} {
          padding: 2rem 0rem;
        }
      }
    `;
  }

  if (type === 'careers-timeline') {
    return css`
      ${styles}

      .flickity-viewport {
        overflow: hidden;
      }

      .${CHILD_CLASS} {
        margin: 0 3.2rem 2.4rem 0;
        width: 80%;
        height: auto;

        @media screen and (min-width: ${theme.breakpoint.small}) {
          width: 486px;
        }
      }
    `;
  }

  if (type === 'built-in-nyc') {
    return css`
      ${styles}
      .flickity-viewport {
        overflow: hidden;
      }
      .${CHILD_CLASS} {
        margin: 0 3.2rem 2.4rem 0;
        width: 80%;
        height: auto;
        @media screen and (min-width: ${theme.breakpoint.small}) {
          width: 33%;
        }
      }
    `;
  }

  return styles;
};

const StyledFlickity = styled(Flickity)`
  ${carouselCellStyles}

`;

const FlickityPlaceHolder = styled.div`
  ${carouselCellStyles}
  display: flex;
  overflow: hidden;

  > * {
    flex: 0 0 auto;
  }
`;

const Carousel = (props) => {
  const isDomReady = useDomLoaded();

  const defaultOptions = {
    arrowShape: 'M 46.19 3.452 L 53.333 10.119 L 18.571 45.357 L 100 45.357 L 100 54.881 L 18.571 54.881 L 53.571 90.119 L 47.381 96.548 L 0 50.357 L 46.19 3.452 Z'
  };

  const options = {
    ...defaultOptions,
    ...props.options
  };

  const children = React.Children.map(props.children, (child) => {
    const childProps = {
      ...child.props,
      className: `${CHILD_CLASS} ${child.props.className ?? ''}`
    };

    return React.cloneElement(child, childProps);
  });

  return (
    <>
      {
        // conditional will ignore waiting for the dom to build the carousel.
        // this helps homepage hero carousels load as carousels first and stops
        // large layout shifts on the page
        isDomReady || props.ignoreDomReady
          ? (
            <StyledFlickity
              className={props.className}
              disableImagesLoaded={props.disableImagesLoaded}
              elementType={props.elementType}
              flickityRef={props.flickityRef}
              options={options}
              reloadOnUpdate={props.reloadOnUpdate}
              static={props.static}
              type={props.type}
              hasUpToFiveProducts={props.hasUpToFiveProducts}
              adjustArrows={props.adjustArrows}
            >
              {children}
            </StyledFlickity>
          )
          : (
            <FlickityPlaceHolder
              type={props.type}
            >
              {children}
            </FlickityPlaceHolder>
          )
      }
    </>
  );
};

Carousel.defaultProps = {
  children: null,
  className: '',
  disableImagesLoaded: false,
  elementType: 'div',
  flickityRef: () => { },
  options: {},
  reloadOnUpdate: false,
  static: false,
  type: undefined,
  hasUpToFiveProducts: false,
  ignoreDomReady: false,
  adjustArrows: false
};

Carousel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  disableImagesLoaded: PropTypes.bool,
  elementType: PropTypes.string,
  flickityRef: PropTypes.func,
  options: PropTypes.object,
  reloadOnUpdate: PropTypes.bool,
  static: PropTypes.bool,
  hasUpToFiveProducts: PropTypes.bool,
  type: PropTypes.oneOf([
    undefined,
    'pdp',
    'three-ways',
    'quickshop',
    'hero',
    'careers-timeline',
    'built-in-nyc'
  ]),
  ignoreDomReady: PropTypes.bool,
  adjustArrows: PropTypes.bool
};

Carousel.whyDidYouRender = true;

export default Carousel;
